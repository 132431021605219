.contacts-page {

  &__text {

    p {
      margin-top: 0;
      margin-bottom: 20px;
      text-align: justify;

      &:last-child {
        margin-bottom: 50px;
      }
    }
  }

  .map {
    border-bottom: 2px solid $third-color;
    margin-bottom: 20px;

    &__img {
      height: 400px;
    }
  }

  &__subscribe {
    margin: 30px 0;
  }
}