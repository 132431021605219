.confirm-check {
  margin-bottom: 15px;
  font-size: 14px;
  position: relative;
  padding-left: 35px;
  display: inline-block;
  input {
    display: none;
    &:checked+.confirm-check__cr::before {
      opacity: 1;
      transition: opacity .2s ease-in;
    }
  }
  &__cr {
    position: absolute;
    top: 4px;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid $third-color;
    &::before {
      opacity: 0;
      transition: opacity .2s ease-in;
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 3px;
      margin: auto 0;
      width: 10px;
      height: 10px;
      background-color: $fourth-color;
    }
  }
  &__text {
    font-weight: 400;
    & a {
      color: inherit;
      text-decoration: none;
      font-weight: 600;
    }
  }
}