.subscribe {
  position: relative;
  padding: 25px 15px;
  @media(min-width: 576px){
    padding: 35px 25px;
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../images/subscribe/subscribe-bg.png") 0 0/cover no-repeat;
  }
  &__place {
    position: relative;
    background: #ffffff;
    padding: 20px 35px;
  }
  &__title {
    font-family: 'Akrobat', sans-serif;
    color: #F55E21;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 28px;
    margin: 0 0 20px;
    @media(min-width: 576px){
      font-size: 36px;
    }
  }
}