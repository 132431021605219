.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: z-index .1s, opacity .1s;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  &_open {
    opacity: 1;
    z-index: 9999;
    transition: z-index .1s, opacity .1s;
  }
  &__window {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;
    background: #FFFFFF;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity .2s, transform .2s;
    @media (min-width: 576px) {
      width: 100%;
      height: initial;
      padding: 30px;
    }
    &_leave-request-popup {
      max-width: 470px;
      .modal {
        &__title {
          text-align: center;
          padding: 0 30px;
        }
        &__subtitle {
          text-align: center;
        }
        &__btn-close {
          top: 20px;
          right: 20px;
        }
      }
    }
    &_call-feedback {
      max-width: 590px;
    }
    &_doctor-call {
      max-width: 590px;
    }
    &_appointment {
      max-width: 950px;
    }
		&_call-analyzes {
			max-width: 590px;
		}
    &_thanks-feedback,
    &_thanks-appointment {
      width: 590px;
      min-height: 370px;
      display: flex;
      justify-content: center;
      align-items: center;
      .modal__title {
        max-width: 320px;
        text-align: center;
      }
    }
    &_open {
      opacity: 1;
      transform: translateY(0);
      transition: opacity .2s, transform .2s;
    }
  }
  &__btn-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
    padding: 0;
    border: 0;
    background: url('../svg/icons/close.svg') center/contain no-repeat;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
    &:active {
      opacity: .6;
    }
    @media (min-width: 576px) {
      top: 45px;
      right: 45px;
    }
  }
  &__title {
    margin-top: 0;
    margin-bottom: 20px;
    padding-right: 30px;
    @media (min-width: 576px) {
      margin-bottom: 30px;
      padding-right: 40px;
    }
  }
  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
    @media (min-width: 576px) {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
  &__body {
    width: 100%;
  }
  &__areas {
    margin-bottom: 30px;
    &_appointment {
      @media (min-width: 992px) {
        width: 55%;
      }
    }
  }
  &__area {
    margin-bottom: 15px;
    @media (min-width: 576px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -2px;
      margin-bottom: 0;
      &:first-child {
        margin-top: 0;
      }
    }
    &_textarea {
      margin-bottom: 50px;
      .modal__textarea {
        @media (min-width: 992px) {
          width: calc(100% - 148px);
        }
      }
    }
  }
  &__label {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 16px;
    color: $font-color;
    font-weight: 600;
    @media (min-width: 576px) {
      max-width: 25%;
      margin-bottom: 0;
    }
    &_required {
      position: relative;
      &::before {
        position: absolute;
        content: '*';
        top: 0;
        right: -10px;
        color: red;
      }
    }
  }
  &__input,
  &__select,
  &__textarea {
    width: 100%;
    padding: 20px 25px;
    border: 2px solid $third-color;
    font-weight: 600;
    font-size: 16px;
    color: $font-color;
    background-color: #fff;
    appearance: none;
    &::placeholder {
      font-weight: 500;
      color: lightgray
    }
    @media (min-width: 576px) {
      width: 70%;
    }
  }
  &__textarea {
    resize: none;
    height: 100px;
  }
  &__checkbox {
    margin-bottom: 15px;
    font-size: 16px;
    input {
      display: none;
      &:checked+.modal__checkbox-fake::before {
        opacity: 1;
        transition: opacity .2s ease-in;
      }
    }
  }
  &__label-checkbox {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-left: 30px;
  }
  &__checkbox-fake {
    position: absolute;
    top: 4px;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid $third-color;
    &::before {
      opacity: 0;
      transition: opacity .2s ease-in;
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 3px;
      margin: auto 0;
      width: 10px;
      height: 10px;
      background-color: $fourth-color;
    }
  }
  &__checkbox-text {
    font-weight: 400;
    & a {
      color: inherit;
      text-decoration: none;
      font-weight: 600;
    }
  }
  &__required {
    position: relative;
    display: flex;
    padding-left: 30px;
    font-size: 14px;
    opacity: 0.7;
    &::before {
      position: absolute;
      content: '*';
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      color: red;
    }
  }
  &__btn {
    margin-top: 45px;
    font-size: 16px;
  }
  &__bottom {
    padding-top: 20px;
  }
  &__sign {
    font-size: 14px;
    &.centered {
      text-align: center;
    }
  }
  &__calendar {
    margin-top: 20px;
    @media (min-width: 992px) {
      width: calc(45% - 30px);
      margin-top: 0;
    }
    .ui-datepicker {
      padding: 0;
    }
    .ui-widget-header {
      font-weight: 600;
      font-size: 14px;
      color: $font-color;
      border: none;
      border-bottom: 2px solid $third-color;
      border-radius: 0;
      background: #fff;
    }
    .ui-state-hover {
      background-color: transparent;
      border: 0;
      opacity: 1;
      transform: scale(1.05);
    }
    .ui-widget-header .ui-icon {
      background-image: url('../images/ui-icons_F55E21_256x240.png');
    }
    .ui-icon-circle-triangle-w {
      background-position: -100px 0px;
    }
    .ui-icon-circle-triangle-e {
      background-position: -30px 0px;
    }
    .ui-datepicker-calendar thead tr {
      background-color: $third-color;
    }
    .ui-datepicker-calendar tbody tr td:first-child {
      border-left: 0;
    }
    .ui-datepicker-calendar tbody tr td:last-child {
      border-right: 0;
    }
    .ui-datepicker-week-end {
      color: $fourth-color;
    }
    .ui-datepicker td {
      margin: 0;
      padding: 0;
      border: 2px solid $third-color;
      &:first-child {
        border-top: 2px solid $third-color;
        border-left: 2px solid $third-color;
      }
    }
    .ui-state-default {
      padding: 7px 10px;
      border: 0;
      background-color: #fff;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
    }
    .ui-widget.ui-widget-content {
      border: 2px solid $third-color;
    }
    .datepicker {
      width: 100%;
    }
    .ui-datepicker {
      width: 100%;
    }
    .ui-datepicker table {
      margin-bottom: -2px;
    }
    .ui-state-active {
      background-color: $first-color;
      color: #fff;
    }
  }
  &__wrap-appointment {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    @media (min-width: 992px) {
      flex-direction: initial;
      justify-content: space-between;
      margin-bottom: 50px;
    }
    .modal__areas {
      margin-bottom: 0;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-direction: initial;
      align-items: center;
    }
    .modal__btn {
      @media (min-width: 992px) {
        max-width: 350px;
        margin-top: 0;
      }
    }
  }
  &__checkbox-wrap {
    @media (min-width: 992px) {
      margin-right: 30px;
    }
  }
  &__text {
    margin: 0;
    text-align: center;
    font-weight: 600;
  }
}