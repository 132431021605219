.subscribe-form {
  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;
  }
  &__main, &__controls {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 30px;
  }
  &__main {
    @media(min-width: 992px){
      max-width: 60%;
      flex: 0 0 60%;
    }
  }
  &__controls {
    @media(min-width: 992px){
      max-width: 40%;
      flex: 0 0 40%;
    }
  }
  &__fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
  &__field {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 12px;
    margin: 0 0 20px;
    @media(min-width: 992px){
      max-width: 50%;
      flex: 0 0 50%;
    }
  }
  &__label {
    font-size: 14px;
    color: $font-color;
    font-weight: 600;
    margin: 0 0 10px;
    display: inline-block;
    &.required {
      position: relative;
      &::before {
        position: absolute;
        content: '*';
        top: 0;
        right: -10px;
        color: red;
      }
    }
  }
  &__input {
    width: 100%;
    padding: 20px 25px;
    border: 2px solid $third-color;
    font-weight: 600;
    font-size: 16px;
    color: $font-color;
    background-color: #fff;
    appearance: none;
    &::placeholder {
      font-weight: 500;
      color: lightgray
    }
  }
  &__message {

  }
  &__required {
    position: relative;
    display: flex;
    padding-left: 10px;
    font-size: 12px;
    color: #C2C2C2;
    &::before {
      position: absolute;
      content: '*';
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      color: red;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__submit {
    padding: 30px 0 0;
  }
  &__confirm-check {
    padding: 20px 0 0;
  }
}